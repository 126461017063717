<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import * as XLSX from "xlsx";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.title,
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "",
      items: [],
      userinfo: [],
      orderlist: [],
    };
  },
  mounted() {
    this.title = this.$t("user.detail.amountlogs");
    this.items = [
      {
        text: this.$t("menuitems.user.list"),
        href: "/user/list",
      },
      {
        text: this.$t("menuitems.user.userdetail"),
        href: "/user/detail?id=" + this.$route.query.id,
      },
      {
        text: this.$t("user.detail.amountlogs"),
        active: true,
      },
    ];
    this.userinfo = JSON.parse(localStorage.getItem("user"));
    this.getamountlist();
    //   this.page.title=this.$t('menuitems.devices.lists')
  },
  methods: {
    getamountlist() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "userchargelogs",
            uid: that.$route.query.id,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          that.orderlist = response.data.list;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    exportToExcel() {
      let newdata = this.orderlist;
      for (let i = 0; i < newdata.length; i++) {
        if (newdata[i].type == 1) {
          newdata[i].type = this.$t("user.detail.amountlog.charge");
        } else if (newdata[i].type == 2) {
          newdata[i].type = this.$t("user.detail.amountlog.consumption");
        } else if (newdata[i].type == 3) {
          newdata[i].type = this.$t("user.detail.amountlog.onlinepayment");
        }

        if (newdata[i].payway == 0) {
          newdata[i].payway = this.$t("user.detail.amountlog.background");
        } else if (newdata[i].payway == 2) {
          newdata[i].payway = this.$t("user.detail.amountlog.alipay");
        } else if (newdata[i].payway == 3) {
          newdata[i].payway = this.$t("user.detail.amountlog.wechatpay");
        }

        if (newdata[i].status == 0) {
          newdata[i].status = this.$t("user.detail.amountlog.notnormal");
        } else {
          newdata[i].status = this.$t("user.detail.amountlog.normal");
        }
        newdata[i].date = newdata[i].sn.substr(1, 4) +
                          "/" +
                          newdata[i].sn.substr(5, 2) +
                          "/" +
                          newdata[i].sn.substr(7, 2) +
                          " " +
                          newdata[i].sn.substr(9, 2) +
                          ":" +
                          newdata[i].sn.substr(11, 2) +
                          ":" +
                          newdata[i].sn.substr(13, 2)
      }
      const worksheet = XLSX.utils.json_to_sheet(newdata);
      XLSX.utils.sheet_add_aoa(worksheet, [[this.$t("user.detail.amountlog.type"), this.$t("user.detail.amountlog.sn"), this.$t("user.detail.price"), this.$t("user.detail.amountlog.payway"), this.$t('user.detail.amountlog.orders'),  this.$t("user.detail.amountlog.notes"), this.$t("user.detail.amountlog.status"), this.$t('user.detail.amountlog.date')]], { origin: "A1" });
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      XLSX.writeFile(workbook, this.$t("user.detail.amountlogs") + `${year}-${month}-${day}` + "tableData.xlsx");
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <el-button type="primary" @click="exportToExcel">{{
              $t("user.detail.amountlog.exportexcel")
            }}</el-button>
            <el-table :data="orderlist" style="width: 100%">
              <el-table-column :label="$t('user.detail.amountlog.type')">
                <template slot-scope="scope">
                  <span v-if="scope.row.type == 1">{{
                    $t("user.detail.amountlog.charge")
                  }}</span>
                  <span v-else-if="scope.row.type == 2">{{
                    $t("user.detail.amountlog.consumption")
                  }}</span>
                  <span v-else-if="scope.row.type == 3">{{
                    $t("user.detail.amountlog.onlinepayment")
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="sn"
                :label="$t('user.detail.amountlog.sn')"
              >
              </el-table-column>
              <el-table-column
                :label="$t('user.detail.price')"
                prop="price"
              ></el-table-column>
              <el-table-column :label="$t('user.detail.amountlog.payway')">
                <template slot-scope="scope">
                  <span v-if="scope.row.payway == 0">{{
                    $t("user.detail.amountlog.background")
                  }}</span>
                  <span v-else-if="scope.row.payway == 2">{{
                    $t("user.detail.amountlog.alipay")
                  }}</span>
                  <span v-else-if="scope.row.payway == 3">{{
                    $t("user.detail.amountlog.wechatpay")
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column :label="$t('user.detail.amountlog.orders')">
                <template slot-scope="scope">
                  <span v-if="scope.row.order">
                    <router-link
                      :to="{
                        path: '/device/orderdetail?id=' + scope.row.order
                      }"
                    >
                      <el-link type="primary">{{
                        $t("user.detail.amountlog.view")
                      }}</el-link>
                    </router-link>
                  </span>
                  <span v-else>{{ $t("user.detail.amountlog.none") }}</span>
                </template>
              </el-table-column>
              <el-table-column :label="$t('user.detail.amountlog.date')">
                <template slot-scope="scope">
                  <span v-if="scope.row.sn.substr(0, 1) == 'G' || scope.row.sn.substr(0, 1) == 'R'">
                    {{
                      scope.row.sn.substr(1, 4) +
                      "/" +
                      scope.row.sn.substr(5, 2) +
                      "/" +
                      scope.row.sn.substr(7, 2) +
                      " " +
                      scope.row.sn.substr(9, 2) +
                      ":" +
                      scope.row.sn.substr(11, 2) +
                      ":" +
                      scope.row.sn.substr(13, 2)
                    }}</span
                  >
                  <span v-else>
                    {{
                      scope.row.sn.substr(0, 4) +
                      "/" +
                      scope.row.sn.substr(4, 2) +
                      "/" +
                      scope.row.sn.substr(6, 2) +
                      " " +
                      scope.row.sn.substr(8, 2) +
                      ":" +
                      scope.row.sn.substr(10, 2) +
                      ":" +
                      scope.row.sn.substr(12, 2)
                    }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="note"
                :label="$t('user.detail.amountlog.notes')"
              >
              </el-table-column>
              <el-table-column :label="$t('user.detail.amountlog.status')">
                <template slot-scope="scope">
                  <span v-if="scope.row.status == 1">{{ $t("user.detail.amountlog.normal") }}</span>
                  <span v-else>
                    <span v-if="scope.row.sn.substr(0, 1) == 'R'">
                      {{ $t("user.detail.amountlog.processing") }}
                    </span>
                    <span v-else>
                      {{ $t("user.detail.amountlog.notnormal") }}
                    </span>
                    
                  </span>
                </template>
              </el-table-column>
            </el-table>
            
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>